import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';

const NavContainer = styled.footer`
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  position: fixed;
`;

const TextContainer = styled.div`
  color: #030303;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 23px;
`;

const Footer = () => {
    const [showFooter, setShowFooter] = React.useState(true);


    const handleCloseFooter = () => {
        setShowFooter(false);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        Cookies.set('termsAccepted', true, { expires: expirationDate });
    };

    const isTermsAccepted = Cookies.get('termsAccepted');

    return (
        <>
            {!isTermsAccepted && showFooter ? (
                <footer
                    style={{
                        padding: '5px',
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        fontSize: "16px",
                        color: '#000000',
                        fontFamily: 'QuickSand',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '1px solid #ccc',
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        maxHeight: '20vh',
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <p style={{
                            margin: '0',
                            paddingLeft: '10px',
                            maxWidth: '80vw',
                            maxHeight: '20vh',
                        }}>
                            This site uses cookies and similar tracking technology. As disclosed in
                            our Privacy Statement, we and our partners may collect personal
                            information and other data.
                            By continuing to use our website, you accept our Privacy Statement and Terms of Service.
                        </p>
                        <div style={{
                            padding: '10px',
                        }}>
                            <a href="/privacy-policy">Privacy Statement</a>
                            <a style={{
                                padding: '10px',
                            }} href="/terms-of-service">Terms of Service</a>
                        </div>
                    </div>
                    <div onClick={handleCloseFooter} style={{ cursor: 'pointer', padding: "20px" }}>
                        <CloseIcon />
                    </div>
                </footer>
            ) : (
                <NavContainer>
                    <TextContainer>A <b>Good Vibes Only</b> Production. © 2023-2024</TextContainer>
                </NavContainer>
            )}
        </>
    );
};

export default Footer;
